import linkedIn from "../assets/socials/linkedIn.svg";
import x from "../assets/socials/x.svg";
import newsletter from "../assets/socials/newsletter.svg";
import blog from "../assets/socials/blog.svg";
import instagram from "../assets/socials/instagram.svg";
import telegram from "../assets/socials/telegram.svg";
import tiktok from "../assets/socials/tiktok.svg";
import whatsapp from "../assets/socials/whatsapp.svg";
import youtube from "../assets/socials/youtube.svg";

import avax from "../assets/events/avax.png";
import cartesi from "../assets/events/cartesi.png";
import sportsFiesta from "../assets/events/sportsFiesta.png";
import community1 from "../assets/community/community1.png";
import community2 from "../assets/community/community2.png";
import community3 from "../assets/community/community3.png";
import community5 from "../assets/community/community5.png";
import community6 from "../assets/community/community6.png";
import community7 from "../assets/community/community7.png";
import community8 from "../assets/community/community8.png";
import community9 from "../assets/community/community9.png";
import community10 from "../assets/community/community10.png";
import community11 from "../assets/community/community11.jpeg";
import community12 from "../assets/community/community12.jpeg";

export const communityPics = [
  community1,
  community12,
  community2,
  community3,
  community10,
  community11,
  community5,
  community11,
  community6,
  community9,
  community7,
  community8,
];

export const communitySocials = [
  {
    icon: linkedIn,
    name: "LinkedIn",
    to: "https://www.linkedin.com/company/blockchainunn/",
    content: "Follow for spaces, events and updates we host",
  },
  {
    icon: x,
    name: "X-Space",
    to: "https://x.com/BlockchainUNN",
    content: "Follow for spaces, events and updates we host",
  },
  {
    icon: telegram,
    name: "Telegram",
    content: "Join our channel to get updated",
  },
  {
    icon: newsletter,
    name: "Newsletter",
    content: "Get weekly dose of news, opportunities and events",
  },
  {
    icon: blog,
    name: "Blog",
    content: "Read cutting edge research from our students & core team.",
  },
  {
    icon: whatsapp,
    name: "Whatsapp",
    content: "Join our whatsapp group to get updated.",
  },
  {
    icon: youtube,
    name: "Youtube",
    content: "Watch our videos on Youtube",
  },
  {
    icon: tiktok,
    name: "Tik-Tok",
    content: "Stay updated on tik-tok",
  },
  {
    icon: instagram,
    name: "Instagram",
    to: "https://www.instagram.com/blockchainunn",
    content: "Let’s connect on Instagram",
  },
];

export const recentEventsData = [
  {
    flyer: avax,
    imageLinks: "",
  },
  {
    flyer: cartesi,
    imageLinks: "",
  },
  {
    flyer: sportsFiesta,
    imageLinks: "",
  },
  {
    flyer: avax,
    imageLinks: "",
  },
  {
    flyer: cartesi,
    imageLinks: "",
  },
];
